import React from "react";
import CtaBanner from "../components/CtaBanner";
import { graphql } from "gatsby";

export default ({ data }) => (
    <React.Fragment>
        muhh
        <div style={{ height: 500 }} />
        <CtaBanner
            image={data.wheel.childImageSharp.fixed}
            alt="Steuerrad mit Sonnenaufgang im Hintergrund"
            angle={95}
            color="#17130f"
            offset={90}
            ctaType="visit"
            slogan="Steuern Sie mit uns in die&nbsp;Zukunft"
        />
    </React.Fragment>
);

export const query = graphql`
    query {
        wheel: file(
            relativePath: { eq: "maximilian-weisbecker-121294-unsplash.jpg" }
        ) {
            childImageSharp {
                fixed(height: 420) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
`;
